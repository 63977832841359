import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
// import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";




import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import KIT1 from '../assets/images/KIT.jpg';


import Tourism from '../assets/images/Tourism.svg';
// import slid2 from '../assets/images/slid2.webp';
import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
import logo from '../assets/images/logo-kar.png';
// import ab2 from '../assets/images/aboutimage.png';


// import ch1 from '../asset1/images/demos/demo-26/banners/banner-5.jpg';
// import ch2 from '../asset1/images/demos/demo-26/banners/banner-6.jpg';
// import ch3 from '../asset1/images/demos/demo-26/bg-1.jpg';
import gh from '../assets/images/gh.png';
import ch3 from '../assets/images/slid.png';
import ch33 from '../assets/images/slid1.jpg';
import ch3335 from '../assets/images/7506746.jpg';
import ch3333 from '../assets/images/Rent-A-Car-Web-Banner-28 - Copy.jpg';
import ch33333 from '../assets/images/6438547.jpg';
import ch333 from '../assets/images/7506746.jpg';
import ch34 from '../assets/images/Unt.png';
import ch343 from '../assets/images/Unt1.jpg';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
import ch344444 from '../assets/images/dollar-bill.png';
import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';
// import ch344444444 from '../assets/images/KIT3.jpg';
// import Toyo from '../assets/images/Toyota-Corolla_20230829_201645_0000-2-1024x767.png';
// import Toyo2 from '../assets/images/Toyota Land Cruiser V8.png';
// import Toyo3 from '../assets/images/Prado TX.png';
// import Toyo4 from '../assets/images/toyota-coaster-bus-car-rental-coaster-bus-3f48fa740baeaaeb202cf19c12b8bc31.png';
import Toyo51 from '../assets/images/moving-black-car-road.jpg';
import Toyo511 from '../assets/images/center.png';
// import ch4 from '../asset1/images/demos/demo-14/slider/slide-2.jpg';
// import ch5 from '../asset1/images/demos/demo-26/banners/banner-3.jpg';
// import ch6 from '../asset1/images/demos/demo-26/banners/banner-4.jpg';
import Carousel1 from './Carosel';
// import Carousel2 from './Carosel1';
// import Carousel3 from './Carosel2';
import Carousel4 from './Carosel3';
// import Carousel5 from './Carosel4';
// import Carousel6 from './Carosel5';
// import Carousel7 from './Carosel6';
import Carousel8 from './Carosel7';
// import Ico from './iconBox';
// import Cate from './Cate';

// import "react-multi-carousel/lib/styles.css";


// import ch6 from '../asset1/images/contact-header-bg.jpg';
import ch1 from '../assets/images/pd1-724x1024.jpg';
// import ch2 from '../assets/images/pd2-724x1024.jpg';
// import ch3 from '../assets/images/pd3-724x1024.jpg';
// import ch4 from '../assets/images/pd4-724x1024.jpg';
// import ch5 from '../assets/images/pd5-724x1024.jpg';




const Home = ( props ) => {
   
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
//     const [options , setoptions] = useState( {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         600: {
//             items: 3,
//         },
//         1000: {
//             items: 4,
//         },
//     },
// })
    // const [Categories , setCategories] = useState([])
    // const [Categories1 , setCategories1] = useState([])
    // const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [product8 , setproduct8] = useState([])
    const [product9 , setproduct9] = useState([])
    const [product10 , setproduct10] = useState([])
    const [product11 , setproduct11] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [SliderPhoto8 , setSliderPhoto8] = useState([])
    // const [ArrivalPhoto , setArrivalPhoto] = useState([])
    // const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    // const [DataPart2 , setDataPart2] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    // const [companieswith , setcompanieswith] = useState([])
    const [couponShow , setcouponShow] = useState(true)
    // const [chk1 , setchk1] = useState(true)
    // const [chk2 , setchk2] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])


    const [CarBookPrice , setCarBookPrice] = useState([])
    
    // const [Ite1 , setIte1] = useState(1)
    // const [Ite2 , setIte2] = useState(1)
    
    // const [fg , s/etfg] = useState(true)




    const [pickup1 , setpickup1] = useState("")
    const [drop1 , setdrop1] = useState("")
    const [pickup2 , setpickup2] = useState("")
    const [drop2 , setdrop2] = useState("")
    const [pickup3 , setpickup3] = useState("")
    const [drop3 , setdrop3] = useState("")
    const [pickup4 , setpickup4] = useState("")
    const [drop4 , setdrop4] = useState("")
    const [pickup , setpickup] = useState("")
    const [drop , setdrop] = useState("")
    const [choosetime , setchoosetime] = useState("")
    const [pickupdate , setpickupdate] = useState("")
    const [dropdate , setdropdate] = useState("")
    const [phone , setphone] = useState("")
    const [car , setcar] = useState("")
    const [email , setemail] = useState("")
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")
    

    useEffect(() =>{

        window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
        }, 1000);
        
 
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        fetch("https://falcon-rides-main-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1[0],res1);
            setSliderPhoto(res1)
          })
       
    
        fetch("https://falcon-rides-main-back.vercel.app/all-CarBookPrice",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            setCarBookPrice(res3)
        
        })
       

      
        fetch("https://falcon-rides-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
      



        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

  
        var data1 =  JSON.parse(localStorage.getItem("Cart")) 
      
        fetch("https://falcon-rides-main-back.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    //    console.log(res6);
    const pro = []
    const pro1 = []
    const pro2 = []
    const pro3 = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
        if ( res7.Arrival1){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro1.push(res7)
        }
        if ( res7.Arrival2){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro2.push(res7)
        }
        if ( res7.Arrival3){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro3.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct8(pro)
    setproduct9(pro1)
    setproduct10(pro2)
    setproduct11(pro3)
    setproduct(pro.slice(0,8))
    setproduct1(pro1.slice(0,8))

    setdis(true)
   })
    
 },[])

 
   
    
    
    
    

const SubmitDataMessage = (e) =>{
  e.preventDefault()
 

if(pickup===""){
  swal("Enter Pickup City")
  return
}
if(drop===""){
  swal("Enter Drop City")
  return
}

                              fetch("https://falcon-rides-main-back.vercel.app/createbooking1",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            pickup ,  
                                            drop ,
                                            choosetime ,
                                            pickupdate , 
                                            dropdate,
                                            phone ,
                                            car ,
                                            email ,
                                            Product_Image_Upload : "A",
                                            // Product_Image_Upload : res2.url,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(res12.Error){
                                            this.setState({displayNone : true})

                                          swal(res12.Error)
                                      }
                                      else{
                                              swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
                                              this.setState({
                                                // Product_Catagories: "",
                                                // Product_Image_Upload : "",
                                                url : "",
                                          })
                                          this.setState({displayNone : true})

                                      }
                                  })
                                  .catch(err=>{
                                    swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");

                                      // swal("There is An Error")                  
                                      }) 
                                  // }
                                  // else{
                                  //   swal("Successfully Upload your Product!");

                                  //     swal("Upload Again")                  
                                  // }
              // })
              // .catch(err=>swal("There is an Error"))

          }  

    
          const setpickup11 = (e)=>{
            setpickup1(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop1){
              let a = false
              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
                  a = true
                  if(CarBookPrice[i].Price){
                    document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car1").innerHTML ="No Any Package"
                  }
                }
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setpickup21 = (e)=>{
            setpickup2(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop2){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === " Toyota Land Cruiser V8"){
                  a=  true

                  if(CarBookPrice[i].Price){
                    document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car2").innerHTML ="No Any Package"
                  }
                }
                
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setpickup31 = (e)=>{
            setpickup3(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop3){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
                  a=  true

                  if(CarBookPrice[i].Price){
                    document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car3").innerHTML ="No Any Package"
                  }
                }
                
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setpickup41 = (e)=>{
            setpickup4(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop1){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
                  a=  true

                  if(CarBookPrice[i].Price){
                    document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car4").innerHTML ="No Any Package"
                  }
                }
                
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setdrop11 = (e)=>{
            setdrop1(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
          if(pickup1){
            let a = false
            for (let i = 0; i < CarBookPrice.length; i++) {
              if(pickup1 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
                a = true  
                if(CarBookPrice[i].Price> 1){
                    console.log("gdfg",CarBookPrice[i].Price)
                    document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                  console.log("gdfg",CarBookPrice[i].Price)
                  document.getElementById("car1").innerHTML ="No Any Package"
                }
              }
              
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

            }
            console.log("gdfg")
          }
          const setdrop21 = (e)=>{
            setdrop2(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(pickup2){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup2 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
                  a=  true

                  if(CarBookPrice[i].Price){
                  document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
                }
                else{
                  document.getElementById("car2").innerHTML ="No Any Package"
                }
              }
              
            }
            if(!a)  document.getElementById("car2").innerHTML ="No Any Package"
            }
          }
          const setdrop31 = (e)=>{
            setdrop3(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(pickup3){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup3 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
                  a=  true

                  if(CarBookPrice[i].Price){
                  document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
                }
                else{
                  document.getElementById("car3").innerHTML ="No Any Package"
                }
              }
              
              }
              if(!a)  document.getElementById("car3").innerHTML ="No Any Package"

            }
          }
          const setdrop41 = (e)=>{
            setdrop4(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(pickup4){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup4 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
                a=  true
                  if(CarBookPrice[i].Price){
                  document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
                }
                else{
                  document.getElementById("car4").innerHTML ="No Any Package"
                }
              }
              
              }
              if(!a)  document.getElementById("car4").innerHTML ="No Any Package"

            }
          }



          const setCarPick1 = (e)=>{
            if(pickup1 && drop1){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup1 === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "Toyota Corolla", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup1,
                      Drop : drop1
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
          const setCarPick2 = (e)=>{
            if(pickup2 && drop2){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup2 === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "THonda BRV", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup2,
                      Drop : drop2
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
          const setCarPick3 = (e)=>{
            if(pickup3 && drop3){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup3 === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "Grand Cabin", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup3,
                      Drop : drop3
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
          const setCarPick4 = (e)=>{
            if(pickup4 && drop4){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup4 === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "Saloon Coaster", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup4,
                      Drop : drop4
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
        return (
            
          <div>
          <div className="page-wrapper">
            
            {/* End .header */}
            <main className="main" style={{maxHeight:  '100%' , overflowX :  "hidden"}}>


















            {/* <main className="main" style={{backgroundColor: '#fafafa'}}> */}
              <div className="intro-section change-height-slider" >
                <div className="container">
                  <div className="row">
                    
                    <div className="col-xl-12 col-md-12 col-12 mb-md-0" style={{padding : "0px"}}>
                      <div className="intro-slider-container">
                        {/* <div className="intro-slider owl-carousel owl-theme owl-nav-inside row cols-1" data-toggle="owl" data-owl-options="{
                                          &quot;nav&quot;: false,
                                          &quot;dots&quot;: true,
                                          &quot;autoplay&quot;: false,
                                          &quot;autoplayTimeout&quot;: 10000,
                                          &quot;animateOut&quot;: &quot;fadeOut&quot;
                                      }"> */}










{/* <Slider {...settings}>   */}

<Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>
  
<div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={ch3335} alt="" className='change-slid' style={{height: "710px"}}/>
  </div>
   
  
<div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={ch3333} alt="" className='change-slid' style={{height: "710px"}}/>
  </div>
   
  
<div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={ch33333} alt="" className='change-slid' style={{height: "710px"}}/>
  </div>
   
  
<div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={ch3} alt="" className='change-slid' style={{height: "710px"}}/>
  </div>
   
{/* <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={ch33} alt="" className='change-slid' style={{height: "710px"}}/>
  </div>
    */}
  
  </Carousel>
 


                        </div>
                  
                    </div>
                   
                  </div>
                </div>
              </div>






              <div id="booking" class="section">
		<div class="section-center">
			<div class="container">
				<div class="row">
					<div class="booking-form">
						<form onSubmit={(e)=>SubmitDataMessage(e)}>
					
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={pickup} onChange={(e)=>setpickup(e.target.value)} >
                    <option value="Choose PickUp">Choose PickUp</option>
                    <option value="Downtown Dubai">Downtown Dubai</option>
                  <option value="Dubai Marina">Dubai Marina</option>
                  <option value="Dubai Hills">Dubai Hills</option>
                  <option value="Jumeirah Beach Residence (JBR)">Jumeirah Beach Residence (JBR)</option>
                  <option value="Palm Jumeirah">Palm Jumeirah</option>
                  <option value="Jumeirah Lakes Towers (JLT)">Jumeirah Lakes Towers (JLT)</option>
                  <option value="Emirates Hills">Emirates Hills</option>
                  <option value="Arabian Ranches 1">Arabian Ranches 1</option>
                  <option value="Arabian Ranches 2">Arabian Ranches 2</option>
                  <option value="Arabian Ranches 3">Arabian Ranches 3</option>
                  <option value="Business Bay">Business Bay</option>
                  <option value="Dubai International Financial Centre (DIFC)">Dubai International Financial Centre (DIFC)</option>
                  <option value="Dubai Silicon Oasis">Dubai Silicon Oasis</option>
                  <option value="Dubai Sports City">Dubai Sports City</option>
                  <option value="Dubai Investment Park (DIP)">Dubai Investment Park (DIP)</option>
                  <option value="Al Barsha">Al Barsha</option>
                  <option value="Al Nahda">Al Nahda</option>
                  <option value="Al Quoz">Al Quoz</option>
                  <option value="Karama">Karama</option>
                  <option value="Deira">Deira</option>
                  <option value="Bur Dubai">Bur Dubai</option>
                  <option value="Al Barsha South">Al Barsha South</option>
                  <option value="Al Satwa">Al Satwa</option>
                  <option value="Al Mankhool">Al Mankhool</option>
                  <option value="Al Wasl">Al Wasl</option>
                  <option value="Al Rashidiya">Al Rashidiya</option>
                  <option value="Al Rigga">Al Rigga</option>
                  <option value="Al Warqa'a">Al Warqa'a</option>
                  <option value="Dubailand">Dubailand</option>

                    </select>
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={drop} onChange={(e)=>setdrop(e.target.value)} >
                    <option value="Downtown Dubai">Downtown Dubai</option>
                  <option value="Dubai Marina">Dubai Marina</option>
                  <option value="Dubai Hills">Dubai Hills</option>
                  <option value="Jumeirah Beach Residence (JBR)">Jumeirah Beach Residence (JBR)</option>
                  <option value="Palm Jumeirah">Palm Jumeirah</option>
                  <option value="Jumeirah Lakes Towers (JLT)">Jumeirah Lakes Towers (JLT)</option>
                  <option value="Emirates Hills">Emirates Hills</option>
                  <option value="Arabian Ranches 1">Arabian Ranches 1</option>
                  <option value="Arabian Ranches 2">Arabian Ranches 2</option>
                  <option value="Arabian Ranches 3">Arabian Ranches 3</option>
                  <option value="Business Bay">Business Bay</option>
                  <option value="Dubai International Financial Centre (DIFC)">Dubai International Financial Centre (DIFC)</option>
                  <option value="Dubai Silicon Oasis">Dubai Silicon Oasis</option>
                  <option value="Dubai Sports City">Dubai Sports City</option>
                  <option value="Dubai Investment Park (DIP)">Dubai Investment Park (DIP)</option>
                  <option value="Al Barsha">Al Barsha</option>
                  <option value="Al Nahda">Al Nahda</option>
                  <option value="Al Quoz">Al Quoz</option>
                  <option value="Karama">Karama</option>
                  <option value="Deira">Deira</option>
                  <option value="Bur Dubai">Bur Dubai</option>
                  <option value="Al Barsha South">Al Barsha South</option>
                  <option value="Al Satwa">Al Satwa</option>
                  <option value="Al Mankhool">Al Mankhool</option>
                  <option value="Al Wasl">Al Wasl</option>
                  <option value="Al Rashidiya">Al Rashidiya</option>
                  <option value="Al Rigga">Al Rigga</option>
                  <option value="Al Warqa'a">Al Warqa'a</option>
                  <option value="Dubailand">Dubailand</option>

                    </select>
                    <span class="select-arrow"></span>

                  </div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Choose Time</span>
										<input class="form-control" type="time" required placeholder="Choose Time"  value={choosetime} onChange={(e)=>setchoosetime(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Email</span>
										<input class="form-control" type="email" required placeholder="Enter Email"  value={email} onChange={(e)=>setemail(e.target.value)}/>
									</div>
								</div>
							
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP DATE</span>
										<input class="form-control" type="date" required  value={pickupdate} onChange={(e)=>setpickupdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF DATE  </span>
										<input class="form-control" type="date" required  value={dropdate} onChange={(e)=>setdropdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PHONE NUMBER
</span>
                    <input class="form-control" type="number" required placeholder="Enter PHONE NUMBER"  value={phone} onChange={(e)=>setphone(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Car</span>
                    <select class="form-control" required="required" aria-required="true"  value={car} onChange={(e)=>setcar(e.target.value)}>
                    <option value="KIA Pegas 2023">KIA Pegas 2023</option>
    <option value="Mitsubishi ASX 2.0 GLX 2WD">Mitsubishi ASX 2.0 GLX 2WD</option>
    <option value="Hyundai Elantra 2024">Hyundai Elantra 2024</option>
    <option value="Hyundai Accent 2024">Hyundai Accent 2024</option>
    <option value="Mazda 3 2024">Mazda 3 2024</option>
    <option value="Mazda 6 2024">Mazda 6 2024</option>
    <option value="KIA K5 2024">KIA K5 2024</option>
    <option value="KIA Picanto 2024">KIA Picanto 2024</option>
    <option value="KIA SONET 2024">KIA SONET 2024</option>
    <option value="KIA Sorento 2024">KIA Sorento 2024</option>
    <option value="KIA CARENS 2024">KIA CARENS 2024</option>
    <option value="KIA Sportage 2024">KIA Sportage 2024</option>
    <option value="KIA SELTOS 2024">KIA SELTOS 2024</option>
    <option value="MG ZS 2024">MG ZS 2024</option>
    <option value="Nissan Sunny 2023">Nissan Sunny 2023</option>
    <option value="Mitsubishi Attrage 2022">Mitsubishi Attrage 2022</option>
    <option value="MG ZS 2023 Model">MG ZS 2023 Model</option>
    <option value="MG5 2022 Model">MG5 2022 Model</option>
    <option value="Hyundai Grand i10 2022">Hyundai Grand i10 2022</option>
    <option value="KIA Pegas 2023 Model">KIA Pegas 2023 Model</option>
    <option value="Hyundai Grand i10 2023">Hyundai Grand i10 2023</option>
    <option value="Hyundai Creta 2023">Hyundai Creta 2023</option>
</select>
										<span class="select-arrow"></span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-btn">
										<button class="submit-btn">Book Now</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
             
  <div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3"> Best Car Rental at Rent a Car Dubai UAE
</h3>

                <div>
            

<Carousel1/>

                       
                </div>  
              </div>

             
             
  {/* <div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3">Today's Best Offer
</h3>
                <div>
            

          
          <div class="row justify-content-center">
              {SliderPhoto8.map((res,i)=>{
                return(
              <div class="col-lg-6 col-sm-6">
                <a href="tel:+923332221927">

                    <img src={res.photo1} alt="" className='rgtsrgtsfgs' style={{margin: "auto",
    maxHeight: "600px",
    width: "400px"}}/>
                </a>
              </div>
                )

              })

              }
              
            
              
          </div>
                       
                </div>  
              </div> */}

             
             

            	<div class="video-banner bg-light">
	                <div class="container align-items-center">
	                	<div class="video-banner-box bg-white">
		                	<div class="row align-items-center">
		                		<div class="col-md-6 mb-3 mb-md-0">
		                			<div class="video-box-content">
		                				<h3 class="video-banner-title h1"><span class="text-primary">About Us </span><strong>Rent a Car Dubai UAE</strong></h3>
	                					<b>Rent a Car Dubai UAE - Your Gateway to Hassle-Free Travel!
</b>
	                					<p>Introducing Dubai's first on-demand car rental marketplace, Rent a Car Dubai UAE! We're here to revolutionize the world of renting with a more convenient and modern approach. Now, renting a car is just a click away, available online for a seamless customer experience.
</p>
	                					<a href="/about" class="btn btn-outline-primary"><span>More About Us</span><i class="icon-long-arrow-right"></i></a>
		                			</div>
		                		</div>
		                		<div class="col-md-6">
		                			<div class="video-poster">
		                				<img src={logo} alt="poster"/>

		                				<div class="video-poster-content">
		                					<a href="https://maps.app.goo.gl/26K1GnQBxXkndn7j8" class="btn-video btn-iframe"><i class="icon-play"></i></a>
		                				</div>
		                			</div>
		                		</div>
		                	</div>
	                	</div>
	                </div>
            	</div>











              <div className="container banner-group-1">
               
                <div className='container' style={{background :  "#f0f0f0"}}>
                  <br/>
             <p class="text-center">AFFORDABLE CARS IN Dubai


</p>
             <h2 class="title mb-4 text-center">Why <b>rentacardubai.today?</b> 

</h2>
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
                                    {/* <i class="icon-local_car_wash"></i> */}
                                    <img src={ch344} alt="" style={{height   : "40px"}}/>
                                </span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Rent a car with and <br /> without a driver
</h3>
                                    <p className="cgg">Options for both self-drive and with driver available



</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch3444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>24/7 Customer Online <br /> Support
</h3>
                                    <p className="cgg" style={{}}>Call us Anywhere Anytime
</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch34444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Clean and safe car
</h3>
                                    <p className="cgg">Efficient safety and cleanliness checks

</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                            <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                            <img src={ch3444444} alt="" style={{height   : "40px"}}/>
</span>
                                <div class="icon-box-content">
                                    <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Reservation Anytime <br /> You Want 
</h3>
                                    <p className="cgg">24/7 Online Reservation




</p>
                                </div>
                            </div>
                        </div>
                    </div>
</div>







             



                <div className="container">
                <div className="row">
                  <div className="col-12" style={{padding : "0px"}}>
                    <div className="">
                      <div className="bg-image d-flex justify-content-center kjkjkjkj" style={{backgroundImage: 'url('+ch343+')',backgroundRepeat : "no-repeat" ,height:  "410px",backgroundColor : "transparent"}}>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>



<div className='container' style={{background :  "#016498"}}>
          
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#016498" , display : "flex"}} >

                  <img src={ch34444} alt="" style={{height   : "40px"}}/>

                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}>30+ 
</h3>
                          <p className="cgg1" style={{}}>NO. OF CARS

</p>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#016498" , display : "flex"}} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <img src={ch344} alt="" style={{height   : "40px"}}/>
                    
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 4,000+

</h3>
                          <p className="cgg1">HAPPY CUSTOMERS




</p>
                      </div>
                  </div>
              </div>


              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#016498" , display : "flex"}} >

                  <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 6
</h3>
                          <p className="cgg1">Operate In Locations

</p>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#016498" , display : "flex"}} >

                  <img src={ch3444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 24/7
</h3>
                          <p className="cgg1">Quality Support

</p>
                      </div>
                  </div>
              </div>
              
          </div>
</div>

<br />








{/* 
<div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3"> Car Rental Monthly Packages
</h3>
<p style={{fontWeight : "600", textAlign : "center"}}>Rent a Car Dubai UAE is providing rent a car service on Monthly Packages also. Our fleet includes all latest models with neat & clean cars like BMW, Mercedes,Honda, Toyota etc. Get with Professional Drivers or without driver rent a car in Karachi or all over Dubai. Call us now to book your car with best car rental company in Dubai.</p>
<br/>
                <div>
            

<Carousel8/>

                       
                </div>  
              </div> */}

<br/>
<div className="container banner-group-1">
               
               <div className='container' style={{background :  "#f0f0f0"}}>
                 <br/>
            <p class="text-center">Services


</p>
            <h2 class="title mb-4 text-center"> <b>rentacardubai.today</b> 

</h2>
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Car Rental Booking
</h3>
                                   <p className="cgg">Experience luxury and convenience with our chauffeur-driven car rental service. Whether you're traveling for business or pleasure, sit back and relax as our professional chauffeurs take the wheel.





</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Tours Booking
</h3>
                                   <p className="cgg" style={{}}>


Book your next adventure with rentacardubai today receive the added benefit of a travel plan. Explore with peace of mind, knowing that you're protected against unforeseen circumstances.
</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> One Way Drop Booking
</h3>
                                   <p className="cgg">Enjoy the convenience of booking at discounted prices with us. Whether you're planning a business trip or a leisurely getaway, we offer a wide selection of accommodations to suit every budget.



</p>
                               </div>
                           </div>
                       </div>
                       
                   </div>
</div>
</div>

<div class="bg-image bg-overlay pt-5 pb-4" style={{backgroundImage: 'url('+Toyo51+')',backgroundRepeat : "no-repeat" , backgroundSize : "cover"}}>
            		<div class="container">
            			<h2 class="title text-center text-white mb-3">What Our Clients Say
</h2>
                  <Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>

	                	{/* <div class="owl-carousel owl-theme owl-testimonials owl-light" data-toggle="owl" 
                           > */}
	                		<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ nice owner and nice service sir thanks for your service and very pocket friendly protocols good behaviour like brothers


”</p>

		                		<cite>
		                		Bilal Anwar

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>
	                		<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ GOod Service and exelent Dealing


”</p>

		                		<cite>
		                		Nadeem jutt

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“  Rent A Car is very good and professional car rental company in Dubai.

”</p>

		                		<cite>
		                		Haji Nazeer Gujjar

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Rent a Car Dubai UAE was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                			Junaid Ghani
		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>
	                	{/* </div> */}
                    </Carousel>

            		</div>
            	</div>
<br />

                <div className="container icon-boxes-section">
           

                <div className="container deal-section">
                <div style={{display : "flex",marginBottom: "10px",
    justifyContent: "space-between"}}>

              <h3 className="title font-weight-bold" style={{  height: "39px",padding  : "0px",margin : "0px" , borderBottom : "1px solid #eb3d32"}}>Cars
</h3>
               <Link to={"/categories/Cars/all-products" }><a  className="heading-right" style={{color : "white" , padding : "7px 8px" ,  background  :"#016498", fontWeight  : "600"}} >VIEW ALL</a></Link>
                        
                </div>
                <div>
             



<Carousel4/>

                       
                </div>  
              </div>
           






                <hr className="mt-2 mb-0" />
              </div>

                
              </div>
              <br />


              {/* <div className="container product-group mb-lg-7 mb-4">
                <div className="row justify-content-center">

            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Karachi-Dubai">Rent A Car Karachi Dubai</Link></p>

                                               <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-karachi">Rent A Car karachi</Link></p>
                                            
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Gulistan-e-Johar">Rent A Car Gulistan-e-Johar</Link></p>
                                             

                          
              
            </div>
            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                           
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Dubai">Rent A Car Dubai</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Karachi-Dubai">Rent A Car Peshawar</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Islamabad">Rent A Car Islamabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Jhelum">Rent A Car Jhelum</Link></p>
                                             

            </div>
            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                            
                                    
                                             
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Lahore">Rent A Car Lahore</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Multan">Rent A Car Multan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Hyderabad">Rent A Car Hyderabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Wedding-Rent-A-Car">Rent A Car Wedding Transport</Link></p>

            </div>
            </div>
            </div> */}

           
              
          
<div class="container">
	                	<h2 class="title text-center mb-3">Our Offices</h2>

	                	<div class="row">
	                		<div class="col-lg-12">
	                			<div class="store">
	                				<div class="row">
	                					<div class="col-sm-5 col-xl-6">
	                						<figure class="store-media mb-2 mb-lg-0">
	                							<img src={logo} alt="image"/>
	                						</figure>
	                					</div>
	                					<div class="col-sm-7 col-xl-6">
	                						<div class="store-content">
	                							<h3 class="store-title">Rent a Car Dubai UAE 
</h3>
	                							<address>                                 38 4B St - Al Karama - Dubai - United Arab Emirates
</address>
	                							<div>                           <a href="tel:+971 56 501 2313">+971 56 501 2313</a></div>
	                						

	                							<h4 class="store-subtitle">Office Open Hours:</h4>
                								<div>Monday - Saturday 8AM-10PM Hours</div>
                								{/* <div>Sunday 11am to 6pm</div> */}

                								<a href="https://maps.app.goo.gl/26K1GnQBxXkndn7j8" class="btn btn-link" target="_blank"><span>View Map</span><i class="icon-long-arrow-right"></i></a>
	                						</div>
	                					</div>
	                				</div>
	                			</div>
	                		</div>
	                		</div>

             
</div>
             

        

              

<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
 
           

<h2>

            1. Experience Luxury with Rent a Car Dubai

</h2>

<p>Rent a Car Dubai offers an extensive range of economic SUVs and luxury cars for rent in Dubai, catering to diverse needs and preferences. With a fleet of well-maintained vehicles, we provide our customers with a seamless car rental experience across various famous locations in Dubai. Our services ensure that you get to drive your desired car at a reasonable price, without compromising on quality. Whether you're looking for a stylish luxury car or a spacious SUV, we've got you covered.</p>
<h2>
2. Rent a Car in Dubai with Style and Comfort
</h2>

<p>Get ready to experience the ultimate comfort and style with Rent a Car Dubai. Our fleet of luxury cars and SUVs is designed to provide you with a memorable driving experience in Dubai. From economy to luxury, we have a wide range of cars to suit your needs and budget. With our car rental services, you can explore Dubai's famous locations in comfort and style.</p>
<h2>
3. Discover the Best Car Rental Options in Dubai
</h2>

<p>Looking for a reliable and affordable car rental service in Dubai? Look no further than Rent a Car Dubai. We offer a wide range of cars, from economy to luxury, to cater to diverse needs and preferences. Our well-maintained fleet, competitive prices, and excellent customer service make us the best car rental option in Dubai.</p>
<h2>
4. Luxury Car Rental in Dubai Made Easy
</h2>

<p>Rent a Car Dubai makes luxury car rental in Dubai easy and affordable. Our fleet of luxury cars is designed to provide you with a premium driving experience. With our user-friendly booking system and competitive prices, you can rent a luxury car in Dubai in just a few clicks. Explore Dubai's famous locations in style and comfort with Rent a Car Dubai.</p>
<h2>
5. Drive in Style with Rent a Car Dubai
</h2>

<p>Drive in style and comfort with Rent a Car Dubai. Our fleet of luxury cars and SUVs is designed to provide you with a memorable driving experience in Dubai. From economy to luxury, we have a wide range of cars to suit your needs and budget. With our car rental services, you can explore Dubai's famous locations in comfort and style.</p>

<h2>
6. Explore Dubai in Comfort with Our Car Rental Services
</h2>

<p>Rent a Car Dubai offers a wide range of cars for rent in Dubai, ensuring that you have a comfortable and memorable driving experience. Our fleet of well-maintained vehicles is designed to cater to diverse needs and preferences. With our car rental services, you can explore Dubai's famous locations in comfort and style.</p>
<h2>
7. Unbeatable Car Rental Deals in Dubai
</h2>

<p>Get the best car rental deals in Dubai with Rent a Car Dubai. We offer a wide range of cars at competitive prices, ensuring that you get the best value for your money. Our fleet of luxury cars and SUVs is designed to provide you with a premium driving experience. Book now and experience the difference!</p>
<h2>
8. Rent a Car in Dubai with Confidence
</h2>

<p>Rent a Car Dubai is a trusted car rental service provider in Dubai. Our fleet of well-maintained vehicles, competitive prices, and excellent customer service make us the preferred choice for car rental in Dubai. With our car rental services, you can explore Dubai's famous locations with confidence.</p>
<h2>
9. Experience the Best of Dubai with Our Car Rental Services
</h2>

<p>Rent a Car Dubai offers a wide range of cars for rent in Dubai, ensuring that you have a memorable driving experience. Our fleet of luxury cars and SUVs is designed to cater to diverse needs and preferences. With our car rental services, you can experience the best of Dubai in comfort and style.</p>
<h2>
10. Car Rental in Dubai Made Simple
</h2>

<p>Rent a Car Dubai makes car rental in Dubai simple and hassle-free. Our user-friendly booking system, competitive prices, and excellent customer service ensure that you have a seamless car rental experience. Book now and explore Dubai's famous locations in comfort and style!</p>
           
           
           
           
           
           
           
           
           
        

            </div>
            </div>
            </div>



              
              {/* End .blog-posts */}
            </main>
            
            {/* End .footer */}
          </div>
          <button id="scroll-top" title="Back to Top">
            <i className="icon-arrow-up" />
          </button>
       
        </div>
        )
    
}

export default Home;