import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import ch34 from '../assets/images/Unt.png';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
// import ch344444 from '../assets/images/dollar-bill.png';
// import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';
import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
import { Carousel } from 'react-responsive-carousel';
import ch343 from '../assets/images/Unt1.jpg';
import ch3444444 from '../assets/images/calendar.png';


import Toyo51 from '../assets/images/moving-black-car-road.jpg';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
            {/* End All Title Box */}
            {/* Start About Page  */}





            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">ABOUT US</h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="">Home</a></li>
                <li className="breadcrumb-item"><a href="#">About Us</a></li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}



          <div class="video-banner bg-light">
	                <div class="container align-items-center">
	                	<div class="video-banner-box bg-white">
		                	<div class="row align-items-center">
		                		<div class="col-md-6 mb-3 mb-md-0">
		                			<div class="video-box-content">
                                    <h3 class="video-banner-title h1"><span class="text-primary">About Us </span><strong>Rent a Car Dubai UAE</strong></h3>
	                					<b>Rent a Car Dubai UAE - Your Gateway to Hassle-Free Travel!
</b>
	                					<p>Introducing Dubai's first on-demand car rental marketplace, Rent a Car Dubai UAE! We're here to revolutionize the world of renting with a more convenient and modern approach. Now, renting a car is just a click away, available online for a seamless customer experience.
</p>
	                		
                            <b style={{color : "black",fontSize :"17px"}}>
                            Our Promise:


                            </b> <br />
	                					<p>    <b style={{color : "black",fontSize :"17px"}}>Easy Online Booking: </b>Rent our cars at your fingertips, hassle-free and with just a few clicks.

</p>
	                					<p>    <b style={{color : "black",fontSize :"17px"}}>Business or Leisure, Anytime: </b>We are always ready to take you to your destination, whether it's day or night.

</p>
	                					<p>    <b style={{color : "black",fontSize :"17px"}}>Top-Notch Service:</b> Our goal is to provide you with the best and most convenient customer experience.

</p>
	                					<p>   With Rent a Car Dubai UAE, make your journeys more memorable. Book today and choose your preferred car with ease!


</p>
	                		
                          
		                			</div>
		                		</div>
		                		<div class="col-md-6">
		                			<div class="video-poster">
		                				<img src={ab1} alt="poster"/>

		                				<div class="video-poster-content">
		                					<a href="https://maps.app.goo.gl/26K1GnQBxXkndn7j8" class="btn-video btn-iframe"><i class="icon-play"></i></a>
		                				</div>
		                			</div>
		                		</div>
		                	</div>
	                	</div>
	                </div>
            	</div>






                <div className="container banner-group-1">
               
               <div className='container' style={{background :  "#f0f0f0"}}>
                 <br/>
            <p class="text-center">AFFORDABLE CARS IN Dubai


</p>
            <h2 class="title mb-4 text-center">Why <b>rentacardubai.today?</b> 

</h2>
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
                                   {/* <i class="icon-local_car_wash"></i> */}
                                   <img src={ch344} alt="" style={{height   : "40px"}}/>
                               </span>
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Rent a car with driver
</h3>
                                   <p className="cgg">Options for both self-drive and with driver available



</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                           <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                           <img src={ch3444} alt="" style={{height   : "40px"}}/>
</span>
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>24/7 Customer Online <br /> Support
</h3>
                                   <p className="cgg" style={{}}>Call us Anywhere Anytime
</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                           <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                           <img src={ch34444} alt="" style={{height   : "40px"}}/>
</span>
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Clean and safe car
</h3>
                                   <p className="cgg">Efficient safety and cleanliness checks

</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                           <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>

                           <img src={ch3444444} alt="" style={{height   : "40px"}}/>
</span>
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Reservation Anytime <br /> You Want 
</h3>
                                   <p className="cgg">24/7 Online Reservation




</p>
                               </div>
                           </div>
                       </div>
                   </div>
</div>







            



               <div className="container">
               <div className="row">
                 <div className="col-12" style={{padding : "0px"}}>
                   <div className="">
                     <div className="bg-image d-flex justify-content-center kjkjkjkj" style={{backgroundImage: 'url('+ch343+')',backgroundRepeat : "no-repeat" ,height:  "410px",backgroundColor : "transparent"}}>
                      
                     </div>
                   </div>
                 </div>
               </div>
             </div>



<div className='container' style={{background :  "#95060D"}}>
         
         <div class="row justify-content-center">
             <div class="col-lg-3 col-sm-6">
                 <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >

                 <img src={ch34444} alt="" style={{height   : "40px"}}/>

                     <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                         <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}>30+ 
</h3>
                         <p className="cgg1" style={{}}>NO. OF CARS

</p>
                     </div>
                 </div>
             </div>
             <div class="col-lg-3 col-sm-6">
                 <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >
                         {/* <i class="icon-local_car_wash"></i> */}
                         <img src={ch344} alt="" style={{height   : "40px"}}/>
                   
                     <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                         <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 4,000+

</h3>
                         <p className="cgg1">HAPPY CUSTOMERS




</p>
                     </div>
                 </div>
             </div>


             <div class="col-lg-3 col-sm-6">
                 <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >

                 <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                     <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                         <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 6
</h3>
                         <p className="cgg1">Operate In Locations

</p>
                     </div>
                 </div>
             </div>
             
             <div class="col-lg-3 col-sm-6">
                 <div class="icon-box text-center cfg" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#95060D" , display : "flex"}} >

                 <img src={ch3444} alt="" style={{height   : "40px"}}/>
                     <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                         <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 24/7
</h3>
                         <p className="cgg1">Quality Support

</p>
                     </div>
                 </div>
             </div>
             
         </div>
</div>

<br />
</div>






<div class="bg-image bg-overlay pt-5 pb-4" style={{backgroundImage: 'url('+Toyo51+')',backgroundRepeat : "no-repeat" }}>
<br />
            		<div class="container">
            			<h2 class="title text-center text-white mb-3">What Our Clients Say
</h2>
                  <Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>

	                	{/* <div class="owl-carousel owl-theme owl-testimonials owl-light" data-toggle="owl" 
                           > */}
	                		<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Rent a Car Dubai UAE was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                		Yasir Arafat

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ I rented 25 days Toyota corola for my needs to travel within the city Karachi. With family. Excellent service. Driver behavior very good. Best rates.

”</p>

		                		<cite>
		                		Haji Nazeer Gujjar

		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>

		                	<blockquote class="testimonial testimonial-icon text-center text-white">
		                		<p style={{color : "white"}}>“ Our Rent a car service experience with Rent a Car Dubai UAE was very good with best new model cars and Driver services appreciated in future.

”</p>

		                		<cite>
		                			Junaid Ghani
		                			<span>Customer</span>
		                		</cite>
		                	</blockquote>
	                	{/* </div> */}
                    </Carousel>

            		</div>
            	</div>
<br />























<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>

      
<h2>

1. Experience Luxury with Rent a Car Dubai

</h2>

<p>Rent a Car Dubai offers an extensive range of economic SUVs and luxury cars for rent in Dubai, catering to diverse needs and preferences. With a fleet of well-maintained vehicles, we provide our customers with a seamless car rental experience across various famous locations in Dubai. Our services ensure that you get to drive your desired car at a reasonable price, without compromising on quality. Whether you're looking for a stylish luxury car or a spacious SUV, we've got you covered.</p>
<h2>
2. Rent a Car in Dubai with Style and Comfort
</h2>

<p>Get ready to experience the ultimate comfort and style with Rent a Car Dubai. Our fleet of luxury cars and SUVs is designed to provide you with a memorable driving experience in Dubai. From economy to luxury, we have a wide range of cars to suit your needs and budget. With our car rental services, you can explore Dubai's famous locations in comfort and style.</p>
<h2>
3. Discover the Best Car Rental Options in Dubai
</h2>

<p>Looking for a reliable and affordable car rental service in Dubai? Look no further than Rent a Car Dubai. We offer a wide range of cars, from economy to luxury, to cater to diverse needs and preferences. Our well-maintained fleet, competitive prices, and excellent customer service make us the best car rental option in Dubai.</p>
<h2>
4. Luxury Car Rental in Dubai Made Easy
</h2>

<p>Rent a Car Dubai makes luxury car rental in Dubai easy and affordable. Our fleet of luxury cars is designed to provide you with a premium driving experience. With our user-friendly booking system and competitive prices, you can rent a luxury car in Dubai in just a few clicks. Explore Dubai's famous locations in style and comfort with Rent a Car Dubai.</p>
<h2>
5. Drive in Style with Rent a Car Dubai
</h2>

<p>Drive in style and comfort with Rent a Car Dubai. Our fleet of luxury cars and SUVs is designed to provide you with a memorable driving experience in Dubai. From economy to luxury, we have a wide range of cars to suit your needs and budget. With our car rental services, you can explore Dubai's famous locations in comfort and style.</p>

<h2>
6. Explore Dubai in Comfort with Our Car Rental Services
</h2>

<p>Rent a Car Dubai offers a wide range of cars for rent in Dubai, ensuring that you have a comfortable and memorable driving experience. Our fleet of well-maintained vehicles is designed to cater to diverse needs and preferences. With our car rental services, you can explore Dubai's famous locations in comfort and style.</p>
<h2>
7. Unbeatable Car Rental Deals in Dubai
</h2>

<p>Get the best car rental deals in Dubai with Rent a Car Dubai. We offer a wide range of cars at competitive prices, ensuring that you get the best value for your money. Our fleet of luxury cars and SUVs is designed to provide you with a premium driving experience. Book now and experience the difference!</p>
<h2>
8. Rent a Car in Dubai with Confidence
</h2>

<p>Rent a Car Dubai is a trusted car rental service provider in Dubai. Our fleet of well-maintained vehicles, competitive prices, and excellent customer service make us the preferred choice for car rental in Dubai. With our car rental services, you can explore Dubai's famous locations with confidence.</p>
<h2>
9. Experience the Best of Dubai with Our Car Rental Services
</h2>

<p>Rent a Car Dubai offers a wide range of cars for rent in Dubai, ensuring that you have a memorable driving experience. Our fleet of luxury cars and SUVs is designed to cater to diverse needs and preferences. With our car rental services, you can experience the best of Dubai in comfort and style.</p>
<h2>
10. Car Rental in Dubai Made Simple
</h2>

<p>Rent a Car Dubai makes car rental in Dubai simple and hassle-free. Our user-friendly booking system, competitive prices, and excellent customer service ensure that you have a seamless car rental experience. Book now and explore Dubai's famous locations in comfort and style!</p>



            </div>
            </div>
            </div>
            {/* End About Page */}
            {/* Start Instagram Feed  */}
            
          </div>
        )
    }
}

export default About;