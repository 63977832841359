import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ch6 from '../asset1/images/contact-header-bg.jpg';
import ch7 from '../asset1/images/stores/img-1.jpg';
import ch8 from '../asset1/images/stores/img-2.jpg';






const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    useEffect(() =>{
        window.scrollTo(0, 0)

    },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        fetch("https://falcon-rides-main-back.vercel.app/touchIn",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
              name  ,
              lname  ,
              email  ,
              subject  ,
              Message  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("SucessFully Send "  )
                setname ("") 
                setlname("") 
                    setemail("") 
                    setsubject("") 
                    setMessage("") 
                // localStorage.setItem("User" , JSON.stringify("Yes") )
                    // props.history.push("/login")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        
    }



        return (
            <> 

            <div>
                <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="">Home</a></li>
              {/* <li className="breadcrumb-item"><a href="#">Pages</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">Contact us</li>
            </ol>
          </div>{/* End .container */}
        </nav>{/* End .breadcrumb-nav */}
        <div className="container">
          <div className="page-header page-header-big text-center" style={{backgroundImage:  'url('+ch6+')'}}>
            <h1 className="page-title text-white">Contact us<span className="text-white">keep in touch with us</span></h1>
          </div>{/* End .page-header */}
        </div>{/* End .container */}

        <div className="page-content pb-0">
          <div className="container">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14434.708366933372!2d55.3017513!3d25.2477988!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43abc3a1172b%3A0x3d5beedc14d9e4bc!2sfalcon%20Rides%20Car%20Rental!5e0!3m2!1sen!2s!4v1721567317100!5m2!1sen!2s" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8201.324173294368!2d67.10645460718712!3d24.922776804741183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb3399f5eb4bf17%3A0x439720a83e4bf3d5!2sYasir%20Transport%20%26%20Rent%20a%20Car!5e0!3m2!1sen!2s!4v1709327337350!5m2!1sen!2s" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    */}
                   <div className="row">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3402.782032591271!2d74.4023161!3d31.4751816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190759509a2d3d%3A0xbe427f23fada2ed5!2sChand%20Tours%20%26%20Car%20Rental%20Agency%20Lahore%20DHA!5e0!3m2!1sen!2s!4v1704275175501!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              <div className="col-lg-6 mb-2 mb-lg-0">
                <h2 className="title mb-1">Contact Information</h2>{/* End .title mb-2 */}
                <p className="mb-3">Welcome to Rent a Car Dubai. Fill in the form to contact us for you query and we will get back to you or call us directly on the number mentioned below

</p>
                <div className="row">
                  <div className="col-sm-7">
                    <div className="contact-info">
                      <h3>The Office</h3>
                      <ul className="contact-list">
                        <li>
                          <i className="icon-map-marker" />
                          38 4B St - Al Karama - Dubai - United Arab Emirates

</li>
                        <li>
                          <i className="icon-phone" />
                           <a href="tel:+971 56 501 2313">+971 56 501 2313</a>
                        </li>
                        
                        <li>
                          <i className="icon-envelope" />
                          <a href="mailto:rentacardubaitoday@gmail.com"><span className="__cf_email__" data-cfemail="">rentacardubaitoday@gmail.com</span></a>
                        </li>
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-7 */}
                  <div className="col-sm-5">
                    <div className="contact-info">
                      <h3>The Office</h3>
                      <ul className="contact-list">
                        <li>
                          <i className="icon-clock-o" />
                          <span className="text-dark">Mon-Sat</span> <br />8AM to 10PM Hours
                        </li>
                        <li>
                          <i className="icon-clock-o" />
                          <span className="text-dark">Customer support timing </span> <br />24/7 Hours
                        </li>
                        
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-5 */}
                </div>{/* End .row */}
              </div>{/* End .col-lg-6 */}
              <div className="col-lg-6">
                <h2 className="title mb-1">Got Any Questions?</h2>{/* End .title mb-2 */}
                <p className="mb-2">Use the form below to get in touch with the sales team</p>
                <form  onSubmit={(e)=>Submitdata(e)}>
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cname" className="sr-only">First Name</label>
                      <input type="text" className="form-control" id="cname" placeholder="Name *" required value={name} onChange={(e)=>setname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cname" className="sr-only">Last Name</label>
                      <input type="text" className="form-control" id="cname" placeholder="Name *" required value={lname} onChange={(e)=>setlname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" className="sr-only">Phone</label>
                      <input type="tel" className="form-control" id="cphone" placeholder="Phone"  value={subject} onChange={(e)=>setsubject(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" className="sr-only">Email</label>
                      <input type="email" className="form-control" id="cemail" placeholder="Email *" value={email} onChange={(e)=>setemail(e.target.value)}  />
                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                  <div className="row">
                        <div className="col-sm-12">
                        <label htmlFor="csubject" className="sr-only">Subject</label>
                        <input type="text" className="form-control" id="csubject" placeholder="Subject" />
                        </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <label htmlFor="cmessage" className="sr-only">Message</label>
                  <textarea className="form-control" cols={30} rows={4} id="cmessage" required placeholder="Message *" defaultValue={""}  value={Message}  onChange={(e)=>setMessage(e.target.value)} />
                  <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm">
                    <span>SUBMIT</span>
                    <i className="icon-long-arrow-right" />
                  </button>
                </form>{/* End .contact-form */}
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}
      </main>{/* End .main */}





              
            </div>
            </>
           
        )
    
}


export default Contact;